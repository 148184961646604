import styled from 'styled-components';
import End from './End';

const Question = ({ questionIndex, question, questionsLength, checkAnswer, wrong }) => {

  if (questionIndex === questionsLength ) {
    return <End wrong={wrong} />
  }
  console.log(questionIndex, questionsLength)
  return (
    <Container>
      <Title>{question?.title}</Title>
      {
        question?.answers.map((answer) => (
            <Answer key={answer.answer} onClick={() => checkAnswer(answer.correct)}>
              {answer.answer}
            </Answer>
          )
        )
      }
    </Container>
  );
}

export default Question;

const Container = styled.div`
  max-width: 800px;
  padding: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: 300;
`;

const Answer = styled.div`
  padding: 10px 20px;
  border: 3px solid red;
  width: 80%;
  margin-bottom: 20px;
  font-weight: 500;
  box-shadow: 10px 10px 0px 0px rgba(214,207,214,1);
  transition: all .1s linear;
  transform: translateY(0);
  user-select: none;

  &:focus, &:active {
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    transform: translateY(5px);
  }
`;
