const questions = [
  {
    title: "How old is Tom?",
    answers: [
      {
        answer: "33",
        correct: false,
      },
      {
        answer: "34",
        correct: true,
      },
      {
        answer: "35",
        correct: false,
      },
    ]
  },
  {
    title: "What is Tom's favourite colour?",
    answers: [
      {
        answer: "Green",
        correct: true,
      },
      {
        answer: "Blue",
        correct: false,
      },
      {
        answer: "Purple",
        correct: false,
      },
    ]
  },
  {
    title: "What is Georgina's favourite colour?",
    answers: [
      {
        answer: "Green",
        correct: true,
      },
      {
        answer: "Blue",
        correct: false,
      },
      {
        answer: "Purple",
        correct: false,
      },
    ]
  },
  {
    title: "What is Ness's favourite colour?",
    answers: [
      {
        answer: "Green",
        correct: false,
      },
      {
        answer: "Blue",
        correct: true,
      },
      {
        answer: "Purple",
        correct: false,
      },
    ]
  },
  {
    title: "What did you get Tom for his 18th birthday?",
    answers: [
      {
        answer: "A box of wine",
        correct: false,
      },
      {
        answer: "A crate of beer",
        correct: false,
      },
      {
        answer: "A keg of beer",
        correct: true,
      },
    ]
  },
  {
    title: "What game did Tom used to fall asleep to you playing?",
    answers: [
      {
        answer: "Doom",
        correct: false,
      },
      {
        answer: "Lemmings",
        correct: false,
      },
      {
        answer: "Command and conquer",
        correct: true,
      },
    ]
  },
  {
    title: "What song did Tom and Ness perform on stage?",
    answers: [
      {
        answer: "Blackbird",
        correct: true,
      },
      {
        answer: "Tears in heaven",
        correct: false,
      },
      {
        answer: "Wonderwall",
        correct: false,
      },
    ]
  },
  {
    title: "What was the first Sega Megadrive game Tom completed?",
    answers: [
      {
        answer: "Paperboy",
        correct: false,
      },
      {
        answer: "Sonic the Hedgehog",
        correct: true,
      },
      {
        answer: "The Teminator",
        correct: false,
      },
    ]
  },
  {
    title: 'At what age did you give Tom the advice "Always think ahead"?',
    answers: [
      {
        answer: "13",
        correct: true,
      },
      {
        answer: "14",
        correct: false,
      },
      {
        answer: "15",
        correct: false,
      },
    ]
  },
  {
    title: "What did Tom spend most of his time doing during his A-levels?",
    answers: [
      {
        answer: "Revising",
        correct: false,
      },
      {
        answer: "Drinking",
        correct: false,
      },
      {
        answer: "Playing computer games",
        correct: true,
      },
    ]
  },
  {
    title: "Which is your favourite child?",
    answers: [
      {
        answer: "Tom",
        correct: true,
      },
      {
        answer: "Georgina",
        correct: false,
      },
      {
        answer: "Ness",
        correct: false,
      },
    ]
  },
];

export default questions;
