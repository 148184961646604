import styled from 'styled-components';

const End = ({ startQuiz, wrong }) => {
  return (
    <Container>
      {
        wrong > 0 ? <Title>You did it with {wrong} incorrect answer(s).</Title> : <Title>You did it!</Title>
      }
      <Title>Happy Father's day!</Title>
      <Content>Enjoy the beers, you earned em!</Content>
      <Content>Love Tom</Content>
    </Container>
  );
};

export default End;

const Container = styled.div`
  margin: auto;
  font-weight: 300;
  text-align: center;
  padding: 10px;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 50px;
`;

const Content = styled.div`
  margin-bottom: 20px;
`;
