import styled from 'styled-components';

const Intro = ({ startQuiz }) => {
  return (
    <Container>
      <Button onClick={startQuiz}>Let's go!</Button>
    </Container>
  );
};

export default Intro;

const Container = styled.div`
  margin: auto;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 3px solid red;
  width: 80%;
  margin-bottom: 20px;
  font-weight: 500;
  box-shadow: 10px 10px 0px 0px rgba(214,207,214,1);
  transition: all .1s ease-in-out;
  transform: translateY(0);
  text-align: center;
  user-select: none;

  &:focus, &:active {
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    transform: translateY(5px);
  }
`;