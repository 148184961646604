import { useState } from 'react';
import questions from './questions';
import Question from './components/Question';
import styled from 'styled-components';
import Intro from './components/Intro';

const App = () => {
  const [isIntro, setIsIntro] = useState(true);
  const [incorrect, setIncorrect] = useState(false);
  const [wrong, setWrong] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);

  const startQuiz = () => {
    setTimeout(() => {
      setIsIntro(false);
    }, 200);
  }

  const checkAnswer = (result) => {
    if (!result) {
      setWrong(wrong => wrong + 1);
      window.navigator.vibrate(10); 
      setIncorrect(true);
      setTimeout(() => {
        setIncorrect(false);
      }, 100)
    } else {
      setTimeout(() => {
        result && setQuestionIndex(index => index + 1);
      }, 200);
    }
  };

  return (
    <Container className={incorrect && 'incorrect'}>
      {
        isIntro ? <Intro startQuiz={startQuiz} /> : <Question wrong={wrong} questionsLength={questions.length} questionIndex={questionIndex} question={questions[questionIndex]} checkAnswer={checkAnswer} />
      }
    </Container>
  );
}

export default App;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: white;
  transition: background-color .1s ease-out;

  &.incorrect {
    background-color: red;
  }
`;
